import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Landing from './Landing';
import { CheckCircleIcon, ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/outline';

function App() {

  return (
    <div className="flex flex-col bg-gray-100 w-screen h-screen justify-center items-center">
      <div className='flex flex-col items-center bg-white w-full md:w-[500px] h-full md:h-[320px] rounded md:shadow p-10'>
        <BrowserRouter>
          <Switch>
            <Route path="/success">
              <div className='font-semibold text-green text-lg font-gilroy'>Payment Successful</div>
              <div className="my-auto">
                <CheckCircleIcon className="w-16 h-16 text-green" />
              </div>
              <div className='w-80 font-semibold text-gray-600 text-center'>
                Your payment has been successfully processed
              </div>
            </Route>
            <Route path="/cancel">
              <div className='font-semibold text-yellow text-lg font-gilroy'>Payment Cancelled</div>
              <div className="my-auto">
                <ExclamationCircleIcon className="w-16 h-16 text-yellow" />
              </div>
              <div className='w-80 font-semibold text-gray-600 text-center'>
                Please return to the payment link if you would like to try again
              </div>
            </Route>
            <Route path="/error">
              <div className='font-semibold text-red text-lg font-gilroy'>An Error has Occurred</div>
              <div className="my-auto">
                <XCircleIcon className="w-16 h-16 text-red" />
              </div>
              <div className='w-80 font-semibold text-gray-600 text-center'>
                Please contact the person who sent you this payment link.
              </div>
            </Route>
            <Route path="/:token" component={Landing} />
            <Route path="*">
              <div className='font-semibold text-blue text-lg font-gilroy'>Portant Secure Payment Portal</div>
              <div className='w-80 font-semibold text-gray-600 text-center my-auto'>
                Capture payments directly from links inserted into your documents
              </div>
              <div className='w-80 font-semibold text-gray-600 text-center'>
                Checkout our <a href='https://www.portant.co' className='underline text-blue'>Portant</a> to learn more
              </div>
            </Route>
          </Switch>
        </BrowserRouter>
      </div>
      <div className='flex items-center text-gray-600 font-semibold font-gilroy m-4 flex-shrink-0'>
        Powered by&nbsp;
        <a href="https://www.portant.co">
          <img src="https://static.portant.co/logo-full-blue.png" className="h-6" alt="Portant Logo" />
        </a>
      </div>
    </div>
  );
}

export default App;
