import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getData } from "./backend";
import { CheckCircleIcon } from '@heroicons/react/outline';

function Landing() {
  const { token = undefined } = useParams<{ token: string }>();
  const [checkoutUrl, setCheckoutUrl] = useState<string | null>();

  useEffect(() => {
    if (token === undefined)
      return;

    getData(`/stripe/checkout/${token}/`)
      .then(({ url }) => { setCheckoutUrl(url); window.location.href = url; })
      .catch(() => window.location.href = "/error");

  }, [token]);

  function openCheckoutUrl() {
    if (!checkoutUrl)
      return;

    window.location.href = checkoutUrl;
  }

  return (
    <>
      <div className='font-semibold text-gray-600 text-lg font-gilroy'>Complete payment with Stripe</div>
      <div className="my-auto">
        {checkoutUrl
          ? <CheckCircleIcon className="w-16 h-16 text-green my" />
          : <img src="https://static.portant.co/portant-loading-blue.svg" className="w-20 h-20" alt="Loading Logo" />
        }
      </div>
      <button className='btn btn-blue w-80' disabled={!checkoutUrl} onClick={openCheckoutUrl}>
        {checkoutUrl ? "Continue to Checkout" : "Generating Checkout Link..."}
      </button>
    </>
  );
}

export default Landing;